import React, { useEffect, useState } from "react";
import PackageLeftBar from "./PackageLeftBar.js";
import PackageListRightPanel from "./PackageListRightPanel.js";
import { packagesServices } from "../../../../APIServices/Editor/packagesServices.jsx";
import { NoDataFoundTable, SpinerDataLoader } from "../../../../Component/NoData/NoDataFoundTable.js";

function Packages({ handleTabChange, editorFullMode }) {
  const [packageLists, setPackageLists] = useState([]);
  const [loader, setLoader] = useState(false);
  const searchPublishedPackageListForConsultant = async (searchData) => {
    let userData = {}
    userData.searchData = searchData
    let data = await packagesServices.searchPublishedPackageListForConsultant(userData)
    setPackageLists(data?.data)
  }

  const getPublishedPackageListForConsultant = async () => {
    setLoader(true)
    let data = await packagesServices.getPublishedPackageListForConsultant()
    setLoader(false)
    setPackageLists(data?.data)
  }

  useEffect(() => {
    getPublishedPackageListForConsultant();
  }, []);

  return (
    <>
      {loader ?
        <div style={{ height: "80vh" }}>
          <SpinerDataLoader />
        </div>
        : packageLists?.length === 0 ?
          <div style={{ height: "80vh" }}>
            <NoDataFoundTable MSG="Data not found" /> </div> :
          <>
            <div className={`d-flex`}>
              <PackageLeftBar
                editorFullMode={editorFullMode}
                searchPublishedPackageListForConsultant={searchPublishedPackageListForConsultant}
              />
              <PackageListRightPanel
                handleTabChange={handleTabChange}
                editorFullMode={editorFullMode}
                packageLists={packageLists}
              />
            </div>
          </>
      }
    </>
  );
}

export default Packages;
