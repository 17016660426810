import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

const TableHeader = ({
  seachData,
  ExportBtn,
  FilterSection,
  AddNew,
  DeleteBtn,
  ChartLine,
  selectOption,
  selectChange,
  handleExport,
  handleChart
}) => {
  const [filter, setFilter] = useState(false);
  return (
    <>
      <div className="hstack justify-content-between p-2 user_filter">
        <div className="hstack gap-2">
          {FilterSection && (
            <div>
              {filter ? (
                <div className="hstack gap-2">
                  <Button
                    onClick={() => {
                      setFilter(false);
                    }}
                    title="Cancel"
                    className="close_circle"
                  >
                    <i className="fa-solid fa-xmark"></i>
                  </Button>
                  <div className="hstack">
                    <Form.Control
                      required
                      value={seachData}
                      type="search"
                      placeholder="Search"
                    />
                    <Button
                      title="Search"
                      style={{ borderRadius: "0px 3px 3px 0px", width: 40 }}
                    >
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </Button>
                  </div>
                </div>
              ) : (
                <Button onClick={() => setFilter(true)} title="Filter">
                  <i className="fa-solid fa-filter"></i>
                </Button>
              )}
            </div>
          )}
          {selectOption && (
            <Form.Select
              onChange={(e) => selectChange(e.target.value)}
              aria-label="Default select example"
              style={{ width: 127, fontSize: 13 }}
            >
              <option value="name">By Name</option>
              <option value="label">By Label</option>
            </Form.Select>
          )}
          {ChartLine && (
            <Button title={"Add Credits"} onClick={handleChart}>
              <i className="fa-solid fa-chart-line"></i>
            </Button>
          )}
          {AddNew && (
            <Button title={"Add Credits"}>
              <i className="fa-solid fa-plus"></i>
            </Button>
          )}
          {DeleteBtn && (
            <Button title="Delete" className="close_circle">
              <i className="fa-solid fa-trash-alt"></i>
            </Button>
          )}
        </div>
        {ExportBtn && (
          <Button onClick={handleExport} title={"Export"}>
            <i className="fa-solid fa-download" style={{marginTop: 0, padding: "8px"}}></i>
          </Button>
        )}
      </div>
      {/* <Export
        
      /> */}
    </>
  );
};

export default TableHeader;
