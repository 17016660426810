import React, { useState } from "react";
import Swal from "sweetalert2";
import { Button, Collapse, Dropdown, Form } from "react-bootstrap";
import { ContextMenuTrigger } from "react-contextmenu";
import RightClick from "../Component/RightClick/RightClick";
import { FaAngleDown } from "react-icons/fa";

const TabList = ({
  tabLists,
  activeTab,
  isHovered,
  searchQuery,
  codeCompare,
  setTabLists,
  editorTheme,
  setIsHovered,
  searchResult,
  handleSetTab,
  setActiveTab,
  handleSearch,
  setEditorTheme,
  editorFullMode,
  setOpenRightTab,
  setEditorFullMode,
  handleCloseButtonClick,
}) => {
  const [open, setOpen] = useState(false);
  const handleCloseAllTab = () => {
    setOpenRightTab("");
    let checkData = codeCompare?.filter((data) => data?.status === true);
    if (checkData?.length > 0) {
      Swal.fire({
        title: "Your Program not Save",
        text: `You want to Close all Tab`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, close it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("activeTabData");
          localStorage.removeItem("tabListData");
          localStorage.removeItem("editorCode");
          localStorage.removeItem("codeCompare");
          setTabLists([]);
          setActiveTab([]);
        }
      });
    } else {
      localStorage.removeItem("activeTabData");
      localStorage.removeItem("tabListData");
      localStorage.removeItem("editorCode");
      localStorage.removeItem("codeCompare");
      setTabLists([]);
      setActiveTab([]);
    }
  };

  const handleCloseOtherTabs = (item) => {
    setOpenRightTab("");
    let checkStatus = codeCompare?.filter((data) => !(data?.id === item?.page));
    if (checkStatus?.length > 0) {
      Swal.fire({
        title: "Your Program not Save",
        text: `You want to Close all other Tab`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, close it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let newTabListsData = [
            {
              name: item.name,
              page: item.page,
            },
          ];
          let newActiveTabsData = [item.name, item.page, item.icon, item.type];

          setTabLists(newTabListsData);
          setActiveTab(newActiveTabsData);

          localStorage.setItem("tabListData", JSON.stringify(newTabListsData));
          localStorage.setItem(
            "activeTabData",
            JSON.stringify(newActiveTabsData)
          );

          let tabCodeList = JSON.parse(localStorage.getItem("editorCode"));
          let activeCode = tabCodeList?.find((data) => data.id === item.page);
          localStorage.setItem(
            "editorCode",
            JSON.stringify([{ code: activeCode?.code, id: activeCode?.id }])
          );

          let codeCompareList = JSON.parse(localStorage.getItem("codeCompare"));
          let codeCompareData = codeCompareList?.find(
            (data) => data.id === item.page
          );
          localStorage.setItem(
            "codeCompare",
            JSON.stringify([
              { status: codeCompareData?.status, id: codeCompareData?.id },
            ])
          );
        }
      });
    } else {
      let newTabListsData = [
        {
          name: item.name,
          page: item.page,
          icon: item.icon,
          type: item.type,
        },
      ];
      let newActiveTabsData = [item.name, item.page, item.icon, item.type];

      setTabLists(newTabListsData);
      setActiveTab(newActiveTabsData);

      localStorage.setItem("tabListData", JSON.stringify(newTabListsData));
      localStorage.setItem("activeTabData", JSON.stringify(newActiveTabsData));

      let tabCodeList = JSON.parse(localStorage.getItem("editorCode"));
      let activeCode = tabCodeList?.find((data) => data.id === item.page);
      if (activeCode) {
        localStorage.setItem(
          "editorCode",
          JSON.stringify([{ code: activeCode?.code, id: activeCode?.id }])
        );
      } else {
        localStorage.setItem("editorCode", JSON.stringify([]));
      }

      let codeCompareList = JSON.parse(localStorage.getItem("codeCompare"));
      let codeCompareData = codeCompareList?.find(
        (data) => data.id === item.page
      );
      if (activeCode) {
        localStorage.setItem(
          "codeCompare",
          JSON.stringify([
            { status: codeCompareData?.status, id: codeCompareData?.id },
          ])
        );
      } else {
        localStorage.setItem("codeCompare", JSON.stringify([]));
      }
    }
  };

  const handleCloseRightTabs = (item) => {
    setOpenRightTab("");
    const index = tabLists.findIndex(
      (tab) => tab.name === item.name && tab.page === item.page
    );
    const matchIndex = codeCompare.findIndex((data) => data.id === item.page);
    const result = matchIndex !== -1 ? codeCompare.slice(matchIndex + 1) : [];
    let checkData = result?.filter((data) => data?.status === true);
    if (checkData?.length > 0) {
      Swal.fire({
        title: "Your Program not Save",
        text: `You want to Close all Right Tab`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, close it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (index !== -1) {
            const newTabListsData = tabLists.slice(0, index + 1);
            let checkTab = newTabListsData.find(
              (data) => data.page === activeTab[1]
            );

            if (!checkTab) {
              let lastIndex = newTabListsData.length - 1;
              let currentTab = newTabListsData[lastIndex];
              let saveActiveData = [
                currentTab.name,
                currentTab.page,
                currentTab.icon,
                currentTab.type,
              ];
              setActiveTab(saveActiveData);
              localStorage.setItem(
                "activeTabData",
                JSON.stringify(saveActiveData)
              );
            }
            setTabLists(newTabListsData);
            localStorage.setItem(
              "tabListData",
              JSON.stringify(newTabListsData)
            );

            let newTabCodeData = [];
            for (const element of newTabListsData) {
              let id = element?.page;
              let tabCodeList = JSON.parse(localStorage.getItem("editorCode"));
              let findCode = tabCodeList?.find((item) => item.id === id);
              newTabCodeData.push(findCode);
            }
            localStorage.setItem("editorCode", JSON.stringify(newTabCodeData));

            let newCodeCompare = [];
            for (const element of newTabListsData) {
              let id = element?.page;
              let codeCompareList = JSON.parse(
                localStorage.getItem("codeCompare")
              );
              let findCode = codeCompareList?.find((item) => item.id === id);
              newCodeCompare.push(findCode);
            }
            localStorage.setItem("codeCompare", JSON.stringify(newCodeCompare));
          }
        }
      });
    } else {
      if (index !== -1) {
        const newTabListsData = tabLists.slice(0, index + 1);
        let checkTab = newTabListsData.find(
          (data) => data.page === activeTab[1]
        );

        if (!checkTab) {
          let lastIndex = newTabListsData.length - 1;
          let currentTab = newTabListsData[lastIndex];
          let saveActiveData = [
            currentTab.name,
            currentTab.page,
            currentTab.icon,
            currentTab.type,
          ];
          setActiveTab(saveActiveData);
          localStorage.setItem("activeTabData", JSON.stringify(saveActiveData));
        }
        setTabLists(newTabListsData);
        localStorage.setItem("tabListData", JSON.stringify(newTabListsData));

        let newTabCodeData = [];
        for (const element of newTabListsData) {
          let id = element?.page;
          let tabCodeList = JSON.parse(localStorage.getItem("editorCode"));
          let findCode = tabCodeList?.find((item) => item.id === id);
          if (findCode) {
            newTabCodeData.push(findCode);
          }
        }
        localStorage.setItem("editorCode", JSON.stringify(newTabCodeData));

        let newCodeCompare = [];
        for (const element of newTabListsData) {
          let id = element?.page;
          let codeCompareList = JSON.parse(localStorage.getItem("codeCompare"));
          let findCode = codeCompareList?.find((item) => item.id === id);
          if (findCode) {
            newCodeCompare.push(findCode);
          }
        }
        localStorage.setItem("codeCompare", JSON.stringify(newCodeCompare));
      }
    }
  };

  const gotoDashboard = () => {
    localStorage.removeItem("activeTabData");
    setActiveTab([]);
  }

  const handleCloseLeftTabs = (item) => {
    setOpenRightTab("");
    const index = tabLists.findIndex(
      (tab) => tab.name === item.name && tab.page === item.page
    );
    const matchIndex = codeCompare.findIndex((data) => data.id === item.page);
    const result = matchIndex !== -1 ? codeCompare.slice(0, matchIndex) : [];
    let checkData = result?.filter((data) => data?.status === true);
    if (checkData?.length > 0) {
      Swal.fire({
        title: "Your Program not Save",
        text: `You want to Close all left Tab`,
        icon: "warning",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, close it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (index !== -1) {
            const newTabListsData = tabLists.slice(index);
            let checkTab = newTabListsData.find(
              (data) => data.page === activeTab[1]
            );
            if (!checkTab) {
              let currentTab = newTabListsData[0];
              let saveActiveData = [
                currentTab.name,
                currentTab.page,
                currentTab.icon,
                currentTab.type,
              ];
              setActiveTab(saveActiveData);
              localStorage.setItem(
                "activeTabData",
                JSON.stringify(saveActiveData)
              );
            }

            setTabLists(newTabListsData);
            localStorage.setItem(
              "tabListData",
              JSON.stringify(newTabListsData)
            );

            let newTabCodeData = [];
            for (const element of newTabListsData) {
              let id = element?.page;
              let tabCodeList = JSON.parse(localStorage.getItem("editorCode"));
              let findCode = tabCodeList?.find((item) => item.id === id);
              if (findCode) {
                newTabCodeData.push(findCode);
              }
            }
            localStorage.setItem("editorCode", JSON.stringify(newTabCodeData));

            let newCodeCompare = [];
            for (const element of newTabListsData) {
              let id = element?.page;
              let codeCompareList = JSON.parse(
                localStorage.getItem("codeCompare")
              );
              let findCode = codeCompareList?.find((item) => item.id === id);
              if (findCode) {
                newCodeCompare.push(findCode);
              }
            }
            localStorage.setItem("codeCompare", JSON.stringify(newCodeCompare));
          }
        }
      });
    } else {
      if (index !== -1) {
        const newTabListsData = tabLists.slice(index);
        let checkTab = newTabListsData.find(
          (data) => data.page === activeTab[1]
        );
        if (!checkTab) {
          let currentTab = newTabListsData[0];
          let saveActiveData = [
            currentTab.name,
            currentTab.page,
            currentTab.icon,
            currentTab.type,
          ];
          setActiveTab(saveActiveData);
          localStorage.setItem("activeTabData", JSON.stringify(saveActiveData));
        }
        setTabLists(newTabListsData);
        localStorage.setItem("tabListData", JSON.stringify(newTabListsData));

        let newTabCodeData = [];
        for (const element of newTabListsData) {
          let id = element?.page;
          let tabCodeList = JSON.parse(localStorage.getItem("editorCode"));
          let findCode = tabCodeList?.find((item) => item.id === id);
          if (findCode) {
            newTabCodeData.push(findCode);
          }
        }
        localStorage.setItem("editorCode", JSON.stringify(newTabCodeData));

        let newCodeCompare = [];
        for (const element of newTabListsData) {
          let id = element?.page;
          let codeCompareList = JSON.parse(localStorage.getItem("codeCompare"));
          let findCode = codeCompareList?.find((item) => item.id === id);
          if (findCode) {
            newCodeCompare.push(findCode);
          }
        }
        localStorage.setItem("codeCompare", JSON.stringify(newCodeCompare));
      }
    }
  };

  return (
    <>
      <div
        className="hstack border-bottom justify-content-between"
        style={{ height: 36 }}
      >
        <div className="hstack pt-1 search_tab_section w-75 noscrollbar">
          <div
            className="hstack"
            style={{
              position: "sticky",
              left: 0,
              zIndex: 9,
              background: "#fff",
            }}
          >
            {tabLists?.length > 0 && (
              <>
                <Button
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  variant="none"
                  style={{ background: "#dee2e6", width: 35, height: 28, marginBottom: -7 }}
                  className="position-relative ms-2"
                >
                  <FaAngleDown style={{ marginTop: -13, marginLeft: -3 }} />
                </Button>
                <Collapse in={open}>
                  <div id="example-collapse-text " style={{ top: 38, left: 12, width: 250 }} className="position-absolute shadow-sm border rounded-1">
                    <div className="search_tabs">
                      <i className="fa-solid fa-magnifying-glass"></i>
                      <input
                        onChange={(e) => handleSearch(e.target.value)}
                        value={searchQuery}
                        type="search"
                        placeholder="Search Tab"
                      />
                    </div>
                    <div className="dataHistory w-100" style={{ border: "none" }}>
                      {searchResult?.map((item, index) => (
                        <div key={index}
                          className={`historyList ${activeTab[1] === item?.page ? "activee_history" : ""}`}
                        >
                          <div
                            className="hstack justify-content-between"
                            key={index}
                            onClick={() =>
                              handleSetTab([item.name, item?.page, item?.icon])
                            }
                          >
                            <span>
                              <i className={item?.icon}> </i> {item?.name}
                            </span>
                            <span
                              className="close_x"
                              title="Close Tab"
                              onClick={(e) => handleCloseButtonClick(item?.page)}
                            >
                              {codeCompare?.find(
                                (data) =>
                                  data?.id === item?.page && data?.status === true
                              ) ? (
                                isHovered === item?.page ? (
                                  <i
                                    onMouseLeave={() => setIsHovered("")}
                                    onMouseEnter={() => setIsHovered(item?.page)}
                                    class="fa-solid fa-xmark"
                                  ></i>
                                ) : (
                                  <i
                                    onMouseLeave={() => setIsHovered("")}
                                    onMouseEnter={() => setIsHovered(item?.page)}
                                    class="fa-solid fa-circle"
                                  ></i>
                                )
                              ) : (
                                <i class="fa-solid fa-xmark"></i>
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      className="text-danger text-center"
                      style={{ cursor: "pointer", background: "#f1d5d5" }}
                      onClick={handleCloseAllTab}
                    >
                      <Form.Label style={{ cursor: "pointer" }}>
                        Close All
                      </Form.Label>
                    </div>
                  </div>
                </Collapse>
              </>
            )}
            {/* {tabLists?.length > 0 && (
              <Dropdown>
                <Dropdown.Toggle
                  style={{ marginRight: 0, transition: "1s" }}
                  variant="none"
                  title="Search Tabs"
                  id="dropdown-basic"
                ></Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="search_tabs">
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      onChange={(e) => handleSearch(e.target.value)}
                      value={searchQuery}
                      type="search"
                      placeholder="Search Tab"
                    />
                  </div>
                  <Dropdown.Divider className="m-0" />
                  <div className="dataHistory w-100" style={{ border: "none" }}>
                    {searchResult?.map((item, index) => (
                      <div key={index}
                        className={`historyList ${activeTab[1] === item?.page ? "activee_history" : ""}`}
                      >
                        <div
                          className="hstack justify-content-between"
                          key={index}
                          onClick={() =>
                            handleSetTab([item.name, item?.page, item?.icon])
                          }
                        >
                          <span>
                            <i className={item?.icon}> </i> {item?.name}
                          </span>
                          <span
                            className="close_x"
                            title="Close Tab"
                            onClick={(e) => handleCloseButtonClick(item?.page)}
                          >
                            {codeCompare?.find(
                              (data) =>
                                data?.id === item?.page && data?.status === true
                            ) ? (
                              isHovered === item?.page ? (
                                <i
                                  onMouseLeave={() => setIsHovered("")}
                                  onMouseEnter={() => setIsHovered(item?.page)}
                                  class="fa-solid fa-xmark"
                                ></i>
                              ) : (
                                <i
                                  onMouseLeave={() => setIsHovered("")}
                                  onMouseEnter={() => setIsHovered(item?.page)}
                                  class="fa-solid fa-circle"
                                ></i>
                              )
                            ) : (
                              <i class="fa-solid fa-xmark"></i>
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Dropdown.Divider className="m-0" />
                  <div
                    className="text-danger text-center"
                    style={{ cursor: "pointer", background: "#f1d5d5" }}
                    onClick={handleCloseAllTab}
                  >
                    <Form.Label style={{ cursor: "pointer" }}>
                      Close All
                    </Form.Label>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            )} */}

            <Dropdown className="HomeReturm">
              <Dropdown.Toggle
                variant="none"
                title="Dashboard"
                id="dropdown-basic"
                onClick={gotoDashboard}
              >
                <i className="fa-solid fa-house" style={{ fontSize: 11 }}></i>
              </Dropdown.Toggle>
            </Dropdown>
          </div>
          {tabLists?.length > 0 && (
            <div className="pt-1 tabbingGroup">
              <div className="hstack gap-1">
                {tabLists?.map((item, index) => (
                  <>
                    <ContextMenuTrigger
                      id={`tabId_${index}`}
                      collect={() => ({ id: index + 1 })}
                    >
                      <div
                        key={index}
                        onClick={() =>
                          handleSetTab([item.name, item?.page, item?.icon, item?.type])
                        }
                        className={`hstack tabHeader gap-2 justify-content-between ${activeTab[1] === item?.page ? "tabHeaderActive" : ""}`}
                      >
                        <p title={item?.name}>
                          <i className={item?.icon}> </i> {item?.name}
                        </p>
                        <button
                          title="Close Tab"
                          onClick={(e) => handleCloseButtonClick(item?.page)}
                        >
                          {codeCompare?.find(
                            (data) =>
                              data?.id === item?.page && data?.status === true
                          ) ? (
                            isHovered === item?.page ? (
                              <i
                                onMouseLeave={() => setIsHovered("")}
                                onMouseEnter={() => setIsHovered(item?.page)}
                                class="fa-solid fa-xmark"
                              ></i>
                            ) : (
                              <i
                                onMouseLeave={() => setIsHovered("")}
                                onMouseEnter={() => setIsHovered(item?.page)}
                                class="fa-solid fa-circle"
                              ></i>
                            )
                          ) : (
                            <i class="fa-solid fa-xmark"></i>
                          )}
                        </button>
                      </div>
                      <RightClick
                        ContextId={`tabId_${index}`}
                        Menu1
                        MenuName1={"Pin Tab"}
                        icons1={
                          <i
                            className="fa-solid fa-thumbtack edit"
                            style={{ transform: "rotate(45deg)" }}
                          ></i>
                        }
                        className1="edit"
                        Menu2
                        MenuName2={"Close Tab"}
                        icons2={
                          <i className="fa-regular fa-circle-xmark edit"></i>
                        }
                        className2="edit"
                        handleClick2={() => handleCloseButtonClick(item?.page)}
                        Menu3
                        MenuName3={"Close Other Tabs"}
                        icons3={
                          <i className="fa-solid fa-circle-xmark edit"></i>
                        }
                        className3="edit"
                        handleClick3={() => handleCloseOtherTabs(item)}
                        Menu4
                        MenuName4={"Close Right Tabs"}
                        icons4={
                          <i
                            className="fa-solid fa-delete-left edit"
                            style={{ transform: "rotate(180deg)" }}
                          ></i>
                        }
                        className4="edit"
                        handleClick4={() => handleCloseRightTabs(item)}
                        Menu5
                        MenuName5={"Close Left Tabs"}
                        icons5={
                          <i className="fa-solid fa-delete-left edit"></i>
                        }
                        className5="edit"
                        handleClick5={() => handleCloseLeftTabs(item)}
                        Menu6
                        MenuName6={"Close All Tabs"}
                        icons6={
                          <i className="fa-solid fa-circle-xmark edit"></i>
                        }
                        className6="edit"
                        handleClick6={handleCloseAllTab}
                      />
                    </ContextMenuTrigger>
                  </>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="header_tool tabSetting">
          <i
            className="fa-solid fa-backward-fast p-2"
            title="Move Backward"
          ></i>
          <i className="fa-solid fa-forward-fast p-2" title="Move Forward"></i>
          <i
            className={`fa-solid p-2 ${editorTheme ? "fa-moon" : "fa-sun"}`}
            title={editorTheme ? "Switch to Dark Mode" : "Switch to Light Mode"}
            onClick={() => setEditorTheme(!editorTheme)}
          ></i>
          <i
            className={`fa-solid p-2 ${!editorFullMode ? "fa-maximize" : "fa-minimize"
              }`}
            title={!editorFullMode ? "Full Mode" : "Minimized Mode"}
            onClick={() => setEditorFullMode(!editorFullMode)}
          ></i>
        </div>
      </div>
    </>
  );
};

export default TabList;
