import React from "react";
import PackagesCharts from "./PackagesChartsData";
import AllLogsData from "../../DashboardCommonData/DashboardLogsData";
import { Card, Col, Container, Row } from "react-bootstrap";
import PurchasedPackagesCharts from "./PurchasedPackagesChartsData";
import AnalyticsData from "./AnalyticsData";
import RecentActivity from "../../DashboardCommonData/RecentActivity";

const DeveloperDashboard = ({ handleTabChange, editorFullMode }) => {
  return (
    <>
      <div
        className={
          editorFullMode
            ? "dashboardFullHeight pb-5"
            : "dashboard_Section_height"
        }
      >
        <Container fluid>
          <Row>
            <Col md={12}>
              <AnalyticsData />
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} className="mb-3">
              <Card className="mb-3 bg-light border rounded-1 h-100">
                <Card.Body>
                  <PackagesCharts />
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} className="mb-3">
              <Card className="mb-3 bg-light border rounded-1 h-100">
                <Card.Body>
                  <PurchasedPackagesCharts />
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4} lg={12} md={12} sm={12} className="mb-3">
              <Card className="mb-3 bg-light border rounded-1 h-100">
                <Card.Body>
                  <div>
                    <p
                      className="m-0"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      Recent Activity
                    </p>
                  </div>
                  <div className="m-0">
                    <div style={{ height: 250, overflow: "auto" }}>
                      <RecentActivity />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12}>
              <AllLogsData handleTabChange={handleTabChange} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DeveloperDashboard;
