import React, { useEffect, useState } from "react";
import { Card, Col, Row, Table } from "react-bootstrap";
import AllLogDataChart from "./DashboardLogDataChartData";
import Moment from "react-moment";
import { loginActivitiesServices } from "../../APIServices/loginActivitiesServices";
import { MdLogin, MdLogout, MdOutlineSmsFailed } from "react-icons/md";

const DashboardLogDataChartData = ({ handleTabChange }) => {
  const [totalChartsLogs, setTotalChartLogs] = useState("");
  const [totalFailedLogs, setTotalFailedLogs] = useState("");

  const getTotalChartLoginHistoryRecord = async () => {
    let data = await loginActivitiesServices.getTotalChartLoginHistoryRecord();
    setTotalChartLogs(data?.chartData);
  };

  const getTotalFailedLoginRecord = async () => {
    let data = await loginActivitiesServices.getTotalFailedLoginRecord();
    setTotalFailedLogs(data?.data);
  };

  const [logdata, setLogData] = useState([]);
  const getLogRecord = async () => {
    let data = await loginActivitiesServices.getLoginHistoryRecord();
    setLogData(data?.data);
  };

  useEffect(() => {
    getLogRecord();
    getTotalChartLoginHistoryRecord();
    getTotalFailedLoginRecord();
  }, []);

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "login":
        return "green";
      case "logout":
        return "red";
      default:
        return "inherit";
    }
  };

  return (
    <>
      <div className="graph__title mt-0 mb-2">
        <p className="m-0">Total Logs </p>
      </div>
      <Row className="mt-2">
        <Col xl={4} lg={6} md={6} sm={12} className="mb-3">
          <div className="h-100">
            <Card
              style={{ padding: "12px 0" }}
              className="mb-3 bg-light border rounded-1"
            >
              <Card.Body className="hstack">
                <div className="hstack">
                  <div className="study_icopn shadow-sm">
                  <MdLogin />
                  </div>
                  <div className="widget-data">
                    <Card.Title className="text-muted mb-1">
                      <b>{totalChartsLogs[0]}</b>
                    </Card.Title>
                    <Card.Text>
                      <p>Total Login Logs</p>
                    </Card.Text>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card
              style={{ padding: "12px 0" }}
              className="mb-3 bg-light border rounded-1"
            >
              <Card.Body className="hstack">
                <div className="hstack">
                  <div className="study_icopn shadow-sm">
                  <MdLogout />
                  </div>
                  <div className="widget-data">
                    <Card.Title className="text-muted mb-1">
                      <b>{totalChartsLogs[1]}</b>
                    </Card.Title>
                    <Card.Text>
                      <p>Total Logout Logs</p>
                    </Card.Text>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card style={{ padding: "13px 0" }} className="bg-light border rounded-1 ">
              <Card.Body className="hstack">
                <div className="hstack">
                  <div className="study_icopn shadow-sm">
                  <MdOutlineSmsFailed />
                  </div>
                  <div className="widget-data">
                    <Card.Title className="text-muted mb-1">
                      <b>{totalFailedLogs}</b>
                    </Card.Title>
                    <Card.Text>
                      <p>Total Failed logs</p>
                    </Card.Text>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
        <Col xl={4} lg={6} md={6} sm={12} className="mb-3">
          <Card className="mb-3 bg-light border rounded-1 h-100">
            <Card.Body>
              <AllLogDataChart totalChartsLogs={totalChartsLogs} />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4} lg={12} md={12} sm={12} className="mb-3">
          <Card className="mb-3 bg-light border rounded-1 h-100">
            <Card.Body>
              <div>
                <h6 style={{ marginBottom: 12 }}>Recent Logs</h6>
              </div>
              <div className="table_content" style={{ maxHeight: "100%" }}>
                <Table striped hover className="mb-3">
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th>In/Out Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logdata.slice(0, 6).map((item, index) => (
                      <tr key={item.id}>
                        <td className="text-capitalize">
                          <div
                            style={{
                              color: getStatusColor(item.status),
                              fontWeight: 500,
                            }}
                          >
                            {item.status}
                          </div>
                        </td>
                        <td>
                          <Moment format="DD MMM YYYY | HH:mm">
                            {item.createdAt}
                          </Moment>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <div className="button_type text-end">
                  <button
                    onClick={(e) => handleTabChange(e, "Log Data", "logdata", "fa-solid fa-clock-rotate-left", "logHistory")}
                  >
                    View All
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DashboardLogDataChartData;
