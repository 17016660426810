import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import OrderStatusChartData from "./ChartData/OrderStatusChartData";
import PackageStatusChartData from "./ChartData/PackageStatusChartData";
import AllLogsData from "../../DashboardCommonData/DashboardLogsData";
import RecentActivity from "../../DashboardCommonData/RecentActivity";
import ProgramByProjectTable from "./TableData/ProgramByProjectTable";
import AnalyticsData from "./AnalyticsData";
import ActiveSubscriptionPlan from "../../Consultant/Dashboard/ActiveSubscriptionPlan";

const ConsultantDashboard = ({ handleTabChange, editorFullMode, userCurrentData}) => {
  return (
    <>
      <div
        className={
          editorFullMode
            ? "dashboardFullHeight pb-5"
            : "dashboard_Section_height"
        }
      >
        <Container fluid>
          <Row>
            <Col md={12}>
              <AnalyticsData />
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} className="mb-3">
              <Card className="mb-3 bg-light border rounded-1 h-100">
                <Card.Body>
                  <OrderStatusChartData />
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4} lg={6} md={6} sm={12} className="mb-3">
              <Card className="mb-3 bg-light border rounded-1 h-100">
                <Card.Body>
                  <PackageStatusChartData />
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4} lg={12} md={12} sm={12} className="mb-3">
              <Card className="mb-3 bg-light border rounded-1 h-100">
                <Card.Body>
                  <ActiveSubscriptionPlan
                    handleTabChange={handleTabChange}
                    userCurrentData={userCurrentData}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xl={8} lg={8} md={8} sm={12} className="mb-3">
              <Card className="mb-3 bg-light border rounded-1 h-100">
                <Card.Body>
                  <ProgramByProjectTable
                    Validtable
                    handleTabChange={handleTabChange}
                  />
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} className="mb-3">
              <Card className="mb-3 bg-light border rounded-1 h-100">
                <Card.Body>
                  <div>
                    <p
                      className="m-0"
                      style={{ fontSize: 14, fontWeight: 500 }}
                    >
                      Recent Activity
                    </p>
                  </div>
                  <div className="m-0">
                    <div style={{ height: 330, overflow: "auto" }}>
                      <RecentActivity />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12}>
              <AllLogsData handleTabChange={handleTabChange} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ConsultantDashboard;
