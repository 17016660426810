import React from "react";
import { MdCancelPresentation, MdOutlineSmsFailed, MdPending } from "react-icons/md";
import { ImMakeGroup } from "react-icons/im";
import { BiPurchaseTag } from "react-icons/bi";
import { Card, Col, Row } from "react-bootstrap";
import { FaFileCircleCheck } from "react-icons/fa6";
import { RiPassExpiredLine } from "react-icons/ri";
import { AiOutlineOrderedList } from "react-icons/ai";
const AnalyticsData = () => {
  return (
    <>
      <div className="graph__title mb-2 mt-2">
        <p className="m-0">Orders & Packages </p>
      </div>
      <Row className="chart__overview mb-0">
        <Col xl={3} lg={4} md={6} sm={12} className="mb-0">
          <Card className="mb-3 bg-light border rounded-1" style={{ height: 100 }}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="study_icopn shadow-sm">
                  <AiOutlineOrderedList />
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1">
                    <b>100</b>
                  </Card.Title>
                  <Card.Text className="m-0">
                    <p>Total Orders</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-0">
          <Card className="mb-3 bg-light border rounded-1" style={{ height: 100 }}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="study_icopn shadow-sm">
                <FaFileCircleCheck />
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1">
                    <b>50</b>
                  </Card.Title>
                  <Card.Text>
                    <p>Complete Orders</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-0">
          <Card className="mb-3 bg-light border rounded-1" style={{ height: 100 }}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="study_icopn shadow-sm">
                <MdPending />
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1">
                    <b>30</b>
                  </Card.Title>
                  <Card.Text>
                    <p>Pending Orders </p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-0">
          <Card className="mb-3 bg-light border rounded-1" style={{ height: 100 }}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="study_icopn shadow-sm">
                <MdCancelPresentation />
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1">
                    <b>20</b>
                  </Card.Title>
                  <Card.Text>
                    <p>Cancelled Orders</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col xl={3} lg={4} md={6} sm={12} className="mb-1">
          <Card className="mb-3 bg-light border rounded-1" style={{ height: 100 }}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="study_icopn shadow-sm">
                <MdOutlineSmsFailed />
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1">
                    <b>500</b>
                  </Card.Title>
                  <Card.Text>
                    <p>Failed Orders</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-1">
          <Card className="mb-3 bg-light border rounded-1" style={{ height: 100 }}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="study_icopn shadow-sm">
                <BiPurchaseTag />
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted"><b>300</b></Card.Title>
                  <Card.Text>
                    <p>Total Purchased Packages</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-1">
          <Card className="mb-3 bg-light border rounded-1" style={{ height: 100 }}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="study_icopn shadow-sm">
                <RiPassExpiredLine />
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1">
                    <b>100</b>
                  </Card.Title>
                  <Card.Text>
                    <p>Expired Packages </p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={3} lg={4} md={6} sm={12} className="mb-1">
          <Card className="mb-3 bg-light border rounded-1" style={{ height: 100 }}>
            <Card.Body className="hstack">
              <div className="hstack">
                <div className="study_icopn shadow-sm">
                <ImMakeGroup />
                </div>
                <div className="widget-data">
                  <Card.Title className="text-muted mb-1">
                    <b>100</b>
                  </Card.Title>
                  <Card.Text>
                    <p>Unexpired Packages</p>
                  </Card.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AnalyticsData;
