import React, { useState } from "react";
import { Table } from "react-bootstrap";
import TableHeader from "../../../Component/TableHeader/TableHeader";
import Pagination from "../../../Component/Paginatation/Pagination";

const FevoritesConsultant = ({ tableRef, onMouseDown, editorFullMode }) => {
  const packages = [
    {
      _id: "1",
      packageName: "Package 1",
      price: 10,
      category: "Ai type",
      addToCart: "👁️",
      removeFromFevorite: "👁️",
    },
    {
      _id: "2",
      packageName: "Package 1",
      price: 10,
      category: "Ai type",
      addToCart: "👁️",
      removeFromFevorite: "👁️",
    },
    {
      _id: "3",
      packageName: "Package 1",
      price: 10,
      category: "Ai type",
      addToCart: "👁️",
      removeFromFevorite: "👁️",
    },
    {
      _id: "4",
      packageName: "Package 1",
      price: 10,
      category: "Ai type",
      addToCart: "👁️",
      removeFromFevorite: "👁️",
    },
    {
      _id: "5",
      packageName: "Package 1",
      price: 10,
      category: "Ai type",
      addToCart: "👁️",
      removeFromFevorite: "👁️",
    },
    {
      _id: "6",
      packageName: "Package 1",
      price: 10,
      category: "Ai type",
      addToCart: "👁️",
      removeFromFevorite: "👁️",
    },
    {
      _id: "7",
      packageName: "Package 1",
      price: 10,
      category: "Ai type",
      addToCart: "👁️",
      removeFromFevorite: "👁️",
    },
    {
      _id: "8",
      packageName: "Package 1",
      price: 10,
      category: "Ai type",
      addToCart: "👁️",
      removeFromFevorite: "👁️",
    },
    {
      _id: "9",
      packageName: "Package 1",
      price: 10,
      category: "Ai type",
      addToCart: "👁️",
      removeFromFevorite: "👁️",
    },
    {
      _id: "10",
      packageName: "Package 1",
      price: 10,
      category: "Ai type",
      addToCart: "👁️",
      removeFromFevorite: "👁️",
    },
    {
      _id: "11",
      packageName: "Package 1",
      price: 10,
      category: "Ai type",
      addToCart: "👁️",
      removeFromFevorite: "👁️",
    },
  ];

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = packages?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(packages?.length / itemsPerPage);

  return (
    <>
      <TableHeader FilterSection ExportBtn />
      <div
        className="table_content overflow-auto mx-2"
        style={{
          maxHeight: editorFullMode ? "calc(100vh - 140px)" : "",
        }}
      >
        <div className="table-container tableLibrarySection">
          <Table
            id="resizable-table"
            className="m-0  custom-table"
            ref={tableRef}
            bordered
            striped
          >
            <thead className="thead-sticky">
              <tr>
                <th
                  onMouseDown={(e) => onMouseDown(e, 0)}
                  style={{ width: 40 }}
                >
                  Sr.
                </th>
                <th onMouseDown={(e) => onMouseDown(e, 1)}>Packages Name</th>
                <th onMouseDown={(e) => onMouseDown(e, 2)}>Price ($)</th>
                <th onMouseDown={(e) => onMouseDown(e, 3)}>Category</th>
                <th
                  onMouseDown={(e) => onMouseDown(e, 4)}
                  style={{ width: 90 }}
                  className="text-center"
                >
                  Add to Cart
                </th>
                <th
                  onMouseDown={(e) => onMouseDown(e, 5)}
                  style={{ width: 70 }}
                  className="text-center"
                >
                  Remove
                </th>
              </tr>
            </thead>
            <tbody>
              {result?.map((item, index) => (
                <tr key={index}>
                  <td>{startIndex + index + 1}</td>
                  <td>{item.packageName}</td>
                  <td>${item.price}</td>
                  <td>{item.category}</td>
                  <td>
                    <div className="text-center">
                      <i
                        className="fa-solid fa-cart-plus"
                        title="add to cart"
                      ></i>
                    </div>
                  </td>
                  <td>
                    <div className="text-center">
                      <i
                        className="fa-solid fa-heart"
                        title="remove from fevorite"
                      ></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        </div>
        <div className="mx-2">
          <Pagination
            totalPage={totalPage}
            pageNumber={pageNumber}
            itemsPerPage={itemsPerPage}
            totalItems={packages?.length}
            setPageNumber={setPageNumber}
            setItemsPerPage={setItemsPerPage}
          />
          </div>
    </>
  );
};

export default FevoritesConsultant;
