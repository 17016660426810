import React, { useState } from "react";
import Moment from "react-moment";
import { Table } from "react-bootstrap";
import Pagination from "../../../Component/Paginatation/Pagination";

const FailedStatusTableData = ({ tableRef, onMouseDown, failedLoginData, editorFullMode }) => {

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const startIndex = (pageNumber - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const result = failedLoginData?.slice(startIndex, endIndex);
  const totalPage = Math.ceil(failedLoginData?.length / itemsPerPage);

  return (
    <>
      <div
        className="table_content overflow-auto mx-2"
        style={{
          maxHeight: editorFullMode ? "calc(100vh - 140px)" : "",
        }}
      >
        <div className="table-container tableLibrarySection">
          <Table
            id="resizable-table"
            className="m-0 custom-table"
            ref={tableRef}
            bordered
            striped
          >
            <thead className="thead-sticky">
              <tr>
                <th onMouseDown={(e) => onMouseDown(e, 0)} style={{ width: 40 }}>
                  Sr.
                </th>
                <th onMouseDown={(e) => onMouseDown(e, 1)}>IP Address</th>
                <th onMouseDown={(e) => onMouseDown(e, 2)}>Time Zone</th>
                <th onMouseDown={(e) => onMouseDown(e, 3)}>Location</th>
                <th onMouseDown={(e) => onMouseDown(e, 4)}>Email</th>
                <th onMouseDown={(e) => onMouseDown(e, 5)}>Password</th>
                <th onMouseDown={(e) => onMouseDown(e, 6)}>Try Time</th>
              </tr>
            </thead>
            <tbody>
              {result?.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1 + startIndex}</td>
                  <td style={{ width: 70 }}>{item.ipAddress}</td>
                  <td style={{ width: 50 }}>{item.timeZone}</td>
                  <td >{item.location}</td>
                  <td style={{ width: 70 }}>{item.email}</td>
                  <td style={{ width: 70 }}>{item.password}</td>
                  <td style={{ width: 50 }}>
                    <Moment format="DD MMM YYYY | HH:mm">{item.createdAt}</Moment>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="mx-2">
          <Pagination
            totalPage={totalPage}
            pageNumber={pageNumber}
            itemsPerPage={itemsPerPage}
            totalItems={failedLoginData?.length}
            setPageNumber={setPageNumber}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default FailedStatusTableData;
