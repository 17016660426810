import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import Draggable from "react-draggable";
import { Card, Form} from "react-bootstrap";
import MyorderTransactionChartByWeek from "./MyorderTransactionChartByWeek";
import MyorderTransationChartByYear from "./MyorderTransationChartByYear";

const MyorderChartDataModal = ({
  show,
  setShow,
  onStart,
  draggleRef,
  bounds,
  onMouseOver,
  onMouseOut,
  disabled,
  title,
  // submit,
}) => {
  
  const [graphWeekData, setGraphWeekData] = useState("");
  const [graphYearData, setGraphYearData] = useState("");
  const [totalWeekCredits, setTotalWeekCredits] = useState(0);
  const [totalYearCredits, setTotalYearCredits] = useState(0);
  const [yearData, setYearData] = useState([]);

  const getBillingTransectionForWeekGraph = async () => {
    const data = await getBillingTransectionForWeekGraph();
    setGraphWeekData(data);
    const weekBillingUsage = data?.weekBillingUsage;
    if (weekBillingUsage) {
      const sum = weekBillingUsage.reduce((acc, value) => acc + value, 0);
      setTotalWeekCredits(sum);
    }
  };

  const getBillingTransectionForYearGraph = async (year) => {
    let data = await getBillingTransectionForYearGraph(year);
    setGraphYearData(data);
    const yearBillingUsage = data?.yearBillingUsage;
    if (yearBillingUsage) {
      const sum = yearBillingUsage.reduce((acc, value) => acc + value, 0);
      setTotalYearCredits(sum);
    }
  };

  const getLastFiveYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < 5; i++) {
      years.push(currentYear - i);
    }
    setYearData(years);
  };

  useEffect(() => {
    getLastFiveYears();
    // getBillingTransectionForWeekGraph();
    // getBillingTransectionForYearGraph("2024");
  }, []);

  return (
    <>
      <Modal
        title={
          <div
            className="modal_Heading"
            onMouseOver={() => {
              if (disabled) {
                onMouseOver();
              }
            }}
            onMouseOut={onMouseOut}
          >
            <h5>{title}</h5>
          </div>
        }
        width={800}
        open={show}
        onOk={() => setShow(!show)}
        onCancel={() => setShow(!show)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <div className="sidebar_Content revenue_side overflow-auto mt-2">
          <div className="hstack justify-content-between mb-3">
            <div className="hstack gap-2">
              <Form.Label className="m-1">Filter By :</Form.Label>
              <div className="selectTransaction">
                <Form.Select
                  aria-label="Default select example"
                  required
                  className="py-1"
                  onChange={(e) =>
                    getBillingTransectionForYearGraph(e.target.value)
                  }
                >
                  {yearData?.map((item, index) => (
                    <option
                      selected={item === yearData[0]}
                      key={index}
                      value={item}
                    >
                      {item}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <Form.Label className="m-1">
              Total Paid : ${totalYearCredits}
            </Form.Label>
          </div>
          <div style={{height: 500, overflow: "auto"}}>
          <Card className="shadow-sm border-0">
            <Card.Body>
              <MyorderTransationChartByYear
                graphYearData={graphYearData}
              />
            </Card.Body>
          </Card>
          <div className="hstack justify-content-between my-3">
            <Form.Label className="m-1">Last 7 Day's</Form.Label>
            <Form.Label className="m-1">
              Total Paid : ${totalWeekCredits}
            </Form.Label>
          </div>
          <Card className="shadow-sm border-0">
            <Card.Body>
              <MyorderTransactionChartByWeek
                graphWeekData={graphWeekData}
              />
            </Card.Body>
          </Card>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default MyorderChartDataModal;
